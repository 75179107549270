<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="d-flex justify-content-center">
      <b-img
          :src="appLogoImage"
          style="max-height: 100px"
          alt="logo"
      />
    </b-link>

    <div class="misc-inner">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('error_page.page_not_found') }} 🕵🏻‍♀️
        </h2>
        <!-- image -->
        <b-img
            style="max-height: 300px"
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
      <p class="d-flex justify-content-center mt-1">
        <b-button
            variant="primary"
            class="mt-1 btn-sm-block"
            :to="{path:'/'}"
        >
          {{ $t('error_page.btn_back') }}
        </b-button>
      </p>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
//@import '@core/scss/vue/pages/page-misc.scss';
</style>
